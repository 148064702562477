import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/website/website/src/mdx/templates/default/MDXDefaultLayout.jsx";
export const frontmatter = {
  title: 'Uploadcare’s CCPA Commitment',
  description: 'We’re committed to helping Uploadcare customers and users understand, and where applicable, comply with the California Consumer Privacy Act (CCPA).',
  header: 'Do Not Share My Personal Information'
};
export const _frontmatter = {};
const layoutProps = {
  frontmatter,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Uploadcare does not sell or rent data and personal information belonging to its
customers, newsletter subscribers, marketing leads and Service enquiries.
Uploadcare processes your personal information only with the help of industry
leaders with high data privacy and security standards.`}</p>
    <p>{`You can exercise the following privacy rights by sending us a support ticket at
`}<a parentName="p" {...{
        "href": "mailto:trust@uploadcare.com"
      }}>{`trust@uploadcare.com`}</a>{`:`}</p>
    <ul>
      <li parentName="ul">{`The right to know which of your personal information is being collected.`}</li>
      <li parentName="ul">{`The right to know whether your personal information is sold or disclosed and
to whom.`}</li>
      <li parentName="ul">{`The right to access your personal information.`}</li>
      <li parentName="ul">{`The right to deletion with certain limitations.`}</li>
      <li parentName="ul">{`The right to say “no” to the sale of your personal information — `}<a parentName="li" {...{
          "href": "#do-not-sell-my-information"
        }}>{`"Do Not Share
My Information"`}</a>{`.`}</li>
    </ul>
    <p>{`Upon receiving your request, we will provide you with the following information
in a commonly used format within 45 days (extensions are possible under the
CCPA):`}</p>
    <ul>
      <li parentName="ul">{`The categories of personal information collected/sold/disclosed for business
purposes in the previous 12 months.`}</li>
      <li parentName="ul">{`Alternatively, if no personal information was sold, that will be written in
our `}<a parentName="li" {...{
          "href": "/about/privacy-policy/"
        }}>{`Privacy Policy`}</a>{`.`}</li>
      <li parentName="ul">{`You will receive “explicit notice” if a third party intends to sell your
personal information.`}</li>
    </ul>
    <h2 {...{
      "id": "do-not-share-my-information"
    }}><a parentName="h2" {...{
        "href": "#do-not-share-my-information"
      }}>{`Do Not Share My Information`}</a></h2>
    <p>{`We use device identifiers (like cookies, beacons, Ad IDs, and IP addresses) and
we may provide this data to other companies in order to improve our websites. We
may also use it to show our ads when you visit other companies’ sites and apps,
or to allow other companies to show their ads on our sites and apps. This
sharing of data may be defined as a sale under the California Consumer Privacy
Act.`}</p>
    <p>{`These device identifiers aren't what you might traditionally think of as
personal information, like your name or phone number, and they don't directly
identify you. However, if you don’t want us to “sell” this information, you can
use the “Do Not Share setting.`}</p>
    <p>{`If you’re not logged in, the setting will only apply to the site where you
selected it.`}</p>
    {
      /* [on/off button] */
    }

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      